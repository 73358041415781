<template>
  <div class="section">
    <div class="container">
      <div class="columns is-large-gap">
        <div class="column is-12">
          <div class="is-flex is-justify-content-space-between">
            <h2 class="has-text-primary has-text-weight-bold is-size-4 m-b-3x">
              {{ $t('Fraction Settings') }}
            </h2>
            <Modal ref="modal" :mask="true" width="800px">
              <template #default="{ hide }">
                <FractionForm
                  :value="fraction"
                  @close="
                    () => {
                      hide();
                      fetchData();
                      fraction = undefined;
                    }
                  "
                  @cancel="() => {
                    fraction = undefined;
                    hide();
                  }"
                />
              </template>
              <template #handler="{ show }">
                <a-button
                  v-permission="['modify-rms']"
                  class="button-primary m-r-3x"
                  type="primary"
                  :size="size"
                  @click.prevent="show"
                >
                  {{ $t('Add Fraction Settings') }}
                </a-button>
              </template>
            </Modal>
          </div>
          <div>
            <a-table
              row-key="id"
              :columns="columns"
              :data-source="fractions"
              :custom-row="rowClicked"
              :loading="loading"
              :pagination="pagination"
              @change="handleTableChange"
            >
              <template #conditionRate="{ record }">
                {{ $filters.number(record.conditionRate) }}
              </template>
              <template #digit="{ record }">
                {{ $filters.number(record.digit) }}
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Fraction Settings": "Fraction Settings",
    "Add Fraction Settings": "Add Fraction Settings",
    "Condition": "Condition",
    "Condition Rate": "Condition Rate",
    "Digit": "Digit",
    "Price Fraction": "Price Fraction",
    "round up": "round up",
    "round down": "round down",
    "Above": "Above",
    "Below": "Below",
  },
  "ja": {
    "Fraction Settings": "端数設定",
    "Add Fraction Settings": "端数設定を追加する",
    "Condition": "条件",
    "Condition Rate": "基準値",
    "Digit": "単位",
    "Price Fraction": "切り上げ/切り捨て",
    "round up": "切り上げ",
    "round down": "切り捨て",
    "Above": "以上",
    "Below": "以下",
  }
}
</i18n>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/Modal';
import pagination from '@/components/mixins/pagination';
import checkPermission from '@/utils/permission';
import FractionForm from '@/views/revenue-management/settings/components/fraction/FractionForm';

export default {
  name: 'GmsTarget',
  components: {
    Modal,
    FractionForm,
  },
  mixins: [pagination],
  data() {
    return {
      size: 'large',
      fractions: [],
      fraction: undefined,
    };
  },
  computed: {
    ...mapState('rms-fractions', ['loading']),
    columns() {
      const baseColumn = [
        {
          title: this.$t('Condition'),
          dataIndex: 'condition',
          customRender: ({ record }) => this.$t(record.condition),
        },
        {
          title: this.$t('Condition Rate'),
          dataIndex: 'conditionRate',
          slots: { customRender: 'conditionRate' },
        },
        {
          title: this.$t('Digit'),
          dataIndex: 'digit',
          slots: { customRender: 'digit' },
        },
        {
          title: this.$t('Price Fraction'),
          dataIndex: 'priceFraction',
          customRender: ({ record }) => this.$t(record.priceFraction),
        },
      ];

      return baseColumn;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      await this.fetchData();
    },
    fetchData() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.$store.dispatch('rms-fractions/list', {
        settingId: this.$route.params.id,
        ...this.$route.query,
      }).then((resp) => {
        const { data, pagination } = resp;

        this.fractions = data;

        this.setPagination(pagination);
      }).finally(() => {
        this.$store.commit('HIDE_FULLSCREEN_LOADER');
      });
    },
    rowClicked(record) {
      return {
        onClick: () => {
          if (!checkPermission(['modify-rms'])) {
            return;
          }

          this.fraction = record;
          this.$refs.modal.show();
        },
      };
    },
  },
};
</script>
