<template>
  <div class="section">
    <div class="container">
      <div class="columns is-large-gap">
        <div class="column is-12">
          <div class="is-flex is-justify-content-space-between">
            <h2 class="has-text-primary has-text-weight-bold is-size-4 m-b-3x">
              {{ $t('GMS Target') }}
            </h2>
            <Modal ref="modal" :mask="true" width="800px">
              <template #default="{ hide }">
                <GmsForm
                  :value="gmsTarget"
                  @close="
                    () => {
                      hide();
                      fetchData();
                      gmsTarget = undefined;
                    }
                  "
                  @cancel="() => {
                    hide();
                    gmsTarget = undefined;
                  }"
                />
              </template>
              <template #handler="{ show }">
                <a-button
                  v-permission="['modify-rms']"
                  class="button-primary m-r-3x"
                  type="primary"
                  :size="size"
                  @click.prevent="show"
                >
                  {{ $t('Add GMS Target') }}
                </a-button>
              </template>
            </Modal>
          </div>
          <div>
            <a-table
              row-key="id"
              :columns="columns"
              :data-source="gmsTargets"
              :custom-row="rowClicked"
              :loading="loading"
              :pagination="pagination"
              @change="handleTableChange"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n src="@/locales/components/months.json"></i18n>
<i18n>
{
  "en": {
    "GMS Target": "GMS Target",
    "Add GMS Target": "Add GMS Target",
  },
  "ja": {
    "GMS Target": "目標GMS",
    "Add GMS Target": "目標GMSを追加する",
    "Year": "年",
  }
}
</i18n>

<script>
import { mapState } from 'vuex';
import { months } from '@/views/revenue-management/settings/components/gms/constants';
import GmsForm from '@/views/revenue-management/settings/components/gms/GmsForm';
import Modal from '@/components/ModalWide';
import pagination from '@/components/mixins/pagination';
import checkPermission from '@/utils/permission';

export default {
  name: 'GmsTarget',
  components: {
    Modal,
    GmsForm,
  },
  mixins: [pagination],
  data() {
    return {
      size: 'large',
      gmsTargets: [],
      gmsTarget: undefined,
    };
  },
  computed: {
    ...mapState('rms-gms-targets', ['loading']),
    columns() {
      const baseColumn = [
        {
          title: this.$t('Year'),
          dataIndex: 'year',
          slots: { customRender: 'year' },
        },
      ];

      for (const month of months) {
        baseColumn.push({
          title: this.$t(month),
          dataIndex: month.toLowerCase(),
          slots: { customRender: month.toLowerCase() },
          customRender: ({ record }) => this.$filters.number(record[month.toLowerCase()]),
        });
      }

      return baseColumn;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      await this.fetchData();
    },
    fetchData() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.$store.dispatch('rms-gms-targets/list', {
        settingId: this.$route.params.id,
        ...this.$route.query,
      }).then((resp) => {
        const { data, pagination } = resp;

        this.gmsTargets = data;

        this.setPagination(pagination);
      }).finally(() => {
        this.$store.commit('HIDE_FULLSCREEN_LOADER');
      });
    },
    rowClicked(record) {
      return {
        onClick: () => {
          if (!checkPermission(['modify-rms'])) {
            return;
          }

          this.gmsTarget = record;
          this.$refs.modal.show();
        },
      };
    },
  },
};
</script>
