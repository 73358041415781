<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <div class="is-align-items-center is-flex is-justify-content-space-between">
        <h2 class="ant-modal-title has-text-primary has-text-weight-bold is-size-4">
          {{ title }}
        </h2>
      </div>
    </div>

    <a-form-item
      class="column is-12"
      :label="$t('Condition')"
      name="condition"
    >
      <a-select
        v-model:value="form.condition"
        :size="size"
        show-search
        style="width: 100%"
      >
        <a-select-option v-for="op in conditions" :key="op.id">
          {{ $t(op.name) }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Condition Rate')" name="conditionRate">
      <a-input-number v-model:value="form.conditionRate" :size="size" style="width: 100%;" />
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Digit')" name="digit">
      <a-select
        v-model:value="form.digit"
        :size="size"
        show-search
        style="width: 100%"
      >
        <a-select-option v-for="op in digits" :key="op">
          {{ op }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item class="column is-12" :label="$t('Price Fraction')" name="priceFraction">
      <a-select
        v-model:value="form.priceFraction"
        :size="size"
        show-search
        style="width: 100%"
      >
        <a-select-option v-for="op in priceFractions" :key="op.id">
          {{ $t(op.name) }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <div class="column is-12 m-t-4x">
      <div class="is-flex is-justify-content-space-between">
        <div style="min-width: 100px">
          <div v-if="value && value.id">
            <DeleteFraction :record="value" @delete="$emit('close')">
              <template #handler="{ show }">
                <a-button
                  :size="size"
                  type="danger"
                  @click="show"
                >
                  {{ $t('Delete') }}
                </a-button>
              </template>
            </DeleteFraction>
          </div>
        </div>
        <div>
          <a-button
            :disabled="loading"
            :size="size"
            class="button-secondary-outline m-r-3x"
            style="min-width: 100px"
            @click="resetFields"
          >
            {{ $t('Cancel') }}
          </a-button>
          <a-button
            :loading="isSubmitting"
            :size="size"
            class="m-r-3x"
            style="min-width: 100px"
            type="primary"
            @click="handleSubmit"
          >
            {{ $t('Save') }}
          </a-button>
        </div>
      </div>
    </div>
  </a-form>
</template>

<i18n>
{
  "en": {
    "Add Fraction Setting": "Add Fraction Setting",
    "Edit Fraction Setting": "Edit Fraction Setting",
    "Condition": "Condition",
    "Condition Rate": "Condition Rate",
    "Digit": "Digit",
    "Price Fraction": "Price Fraction",
    "Above": "Above",
    "Below": "Below",
    "condition-required": "Condition is required.",
    "condition-rate-required": "Condition rate is required.",
    "digit-limit-required": "Digit limit is required.",
    "round up": "round up",
    "round down": "round down",
  },
  "ja": {
    "Fraction Settings": "端数設定",
    "Edit Fraction Setting": "端数設定を編集する",
    "Add Fraction Setting": "端数設定を追加する",
    "Condition": "条件",
    "Condition Rate": "基準値",
    "Digit": "単位",
    "Price Fraction": "切り上げ/切り捨て",
    "Above": "以上",
    "Below": "以下",
    "condition-required": "条件は必須項目です",
    "condition-rate-required": "基準値は必須項目です",
    "digit-limit-required": "単位は必須項目です",
    "round up": "切り上げ",
    "round down": "切り捨て",
  }
}
</i18n>

<script>
import { FRACTION_CONDITION, PRICING_FRACTION } from '@/config/revenue-managements';
import DeleteFraction from '@/views/revenue-management/settings/components/fraction/DelFraction';

export default {
  name: 'FractionModal',
  components: {
    DeleteFraction,
  },
  props: {
    value: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      isSubmitting: false,
      form: {
        condition: undefined,
        conditionRate: undefined,
        digit: undefined,
        priceFraction: PRICING_FRACTION[0].name, // round up
      },
      priceFractions: PRICING_FRACTION,
      conditions: FRACTION_CONDITION,
      digits: [100, 500, 1000],
      size: 'large',
    };
  },
  computed: {
    title() {
      return this.value && this.value.id
        ? this.$t('Edit Fraction Setting')
        : this.$t('Add Fraction Setting');
    },
    rules() {
      const rules = {
        condition: [
          {
            required: true,
            message: this.$t('condition-required'),
            trigger: ['change', 'blur'],
          },
        ],
        conditionRate: [
          {
            type: 'number',
            required: true,
            message: this.$t('condition-rate-required'),
            trigger: ['change', 'blur'],
          },
        ],
        digit: [
          {
            type: 'number',
            required: true,
            message: this.$t('digit-limit-required'),
            trigger: ['change', 'blur'],
          },
        ],
        priceFraction: [
          {
            required: true,
            message: this.$t('Price fraction is required.'),
            trigger: ['change', 'blur'],
          },
        ],
      };

      return rules;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        if (nv) {
          const keys = Object.keys(this.form);
          keys.forEach((k) => {
            if (k in nv) {
              this.form[k] = nv[k];
            }
          });
        }
      },
    },
  },
  methods: {
    async handleSubmit() {
      try {
        this.isSubmitting = true;
        await this.$refs.form.validate();

        if (this.value && this.value.id) {
          await this.$store.dispatch('rms-fractions/edit', {
            ...this.form,
            settingId: this.$route.params.id,
            id: this.value.id,
          });
        } else {
          await this.$store.dispatch('rms-fractions/add', {
            ...this.form,
            settingId: this.$route.params.id,
          });
        }

        this.isSubmitting = false;
        this.$emit('close');
      } catch (error) {
        this.isSubmitting = false;
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>
