<template>
  <a-form
    ref="form"
    :model="form"
    :rules="rules"
    form-layout="vertical"
  >
    <div class="column is-12">
      <div class="is-align-items-center is-flex is-justify-content-space-between">
        <h2 class="ant-modal-title has-text-primary has-text-weight-bold is-size-4">
          {{ title }}
        </h2>
      </div>
    </div>

    <div class="columns is-12">
      <div
        class="p-1 is-flex is-justify-content-space-between is-align-items-end"
        style="width: 100%;"
      >
        <a-form-item
          class="column is-4 p-1"
          :label="$t('Year')"
          name="year"
        >
          <a-input-number
            v-model:value="form.year"
            :size="size"
            type="text"
            style="width: 150px;"
          />
        </a-form-item>
        <div v-if="value && value.id">
          <DeleteGms :record="value" @delete="$emit('close')">
            <template #handler="{ show }">
              <a-button
                :size="size"
                type="danger"
                @click="show"
              >
                {{ $t('Delete') }}
              </a-button>
            </template>
          </DeleteGms>
        </div>
      </div>
    </div>

    <div class="columns is-12" style="flex-wrap: wrap;">
      <hr class="my-2" style="width: 100%;">

      <template v-for="(month, idx) in months" :key="idx">
        <a-form-item
          class="column is-2 p-1"
          :name="month.toLowerCase()"
          :label="$t(month)"
        >
          <InputFormattedNumber
            v-model:value="form[month.toLowerCase()]"
            :size="size"
            @input="handleInput"
          />
        </a-form-item>
      </template>
    </div>

    <div class="column is-12 has-text-right">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="isSubmitting"
        :size="size"
        style="min-width: 155px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n src="@/locales/components/months.json"></i18n>
<i18n src="@/locales/components/months-validation.json"></i18n>
<i18n>
{
  "en": {
    "Year": "Year",
    "Add GMS Target": "Add GMS Target",
    "Edit GMS Target": "Edit GMS Target",
    "error": {
      "year-required": "Year is required.",
      "year-min": "The year must be at least 1990.",
      "year-max": "The year may not be greater than 2100.",
    }
  },
  "ja": {
    "Year": "年",
    "Add GMS Target": "目標GMSを追加する",
    "Edit GMS Target": "目標GMSを編集する",
    "error": {
      "year-required": "年は必須項目です",
      "year-min": "1990年以前は入力できません",
      "year-max": "2100年以降は入力できません",
    }
  }
}
</i18n>

<script>
import { months } from '@/views/revenue-management/settings/components/gms/constants';
import DeleteGms from '@/views/revenue-management/settings/components/gms/DelGms';
import InputFormattedNumber from '@/components/InputFormattedNumber';

export default {
  name: 'GmsForm',
  components: {
    DeleteGms,
    InputFormattedNumber,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      form: {
        year: undefined,
      },
      isSubmitting: false,
      size: 'large',
      months,
      isInputActive: false,
    };
  },
  computed: {
    title() {
      if (this.value && this.value.id) {
        return this.$t('Edit GMS Target');
      }

      return this.$t('Add GMS Target');
    },
    rules() {
      const rules = {
        year: [
          {
            type: 'number',
            required: true,
            message: this.$t('error.year-required'),
            trigger: ['change', 'blur'],
          },
          {
            validator: (rule, value) => new Promise((resolve, reject) => {
              if (value < 1989) {
                reject(this.$t('error.year-min'));
              } else if (value > 2100) {
                reject(this.$t('error.year-max'));
              } else {
                resolve();
              }
            }),
            trigger: ['change', 'blur'],
          },
        ],
      };

      for (const month of months) {
        rules[month.toLowerCase()] = [{
          type: 'number',
          required: true,
          message: this.$t(`${month} is required`),
          trigger: ['change', 'blur'],
        }];
      }

      return rules;
    },
  },
  watch: {
    value: {
      handler(nv) {
        if (nv) {
          this.populateFormData(nv);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
    async handleSubmit() {
      try {
        this.isSubmitting = true;
        await this.$refs.form.validate();

        if (this.value && this.value.id) {
          await this.$store.dispatch('rms-gms-targets/edit', {
            ...this.form,
            settingId: this.$route.params.id,
            id: this.value.id,
          });
        } else {
          await this.$store.dispatch('rms-gms-targets/add', {
            ...this.form,
            settingId: this.$route.params.id,
          });
        }

        this.isSubmitting = false;
        this.$emit('close');
      } catch (error) {
        this.isSubmitting = false;
      }
    },
    populateFormData(value) {
      for (const month of months) {
        this.form[month.toLowerCase()] = undefined;
      }

      const keys = Object.keys(this.form);
      keys.forEach((k) => {
        if (k in value) {
          this.form[k] = value[k];
        }
      });
    },
    handleInput(val, key) {
      this.form[key] = val;
    },
  },
};
</script>
