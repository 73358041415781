<template>
  <div class="section">
    <div v-if="!loading" class="container">
      <a-form ref="form" :model="form" :rules="rules">
        <div class="columns is-large-gap">
          <div class="column is-12">
            <h2 class="has-text-primary has-text-weight-bold is-size-4 m-b-3x">
              {{ $t('Competitor Setting') }}
            </h2>
            <div class="columns" style="min-height: 35vh;">
              <div class="column is-4">
                <div class="td">
                  <div class="has-text-black has-text-weight-bold">
                    {{ $t('Radius Search') }}
                  </div>
                  <div class="mt-1">
                    {{ $t('Radius (km) from property itself') }}
                  </div>
                  <div>
                    <a-form-item
                      :validate-status="errors.radius && 'error'"
                      :help="errors.radius"
                    >
                      <a-input
                        v-model:value="form.radius"
                        type="number"
                        suffix="km"
                        style="width: 80%;"
                        :size="size"
                        @keyup="validateForm"
                      />
                    </a-form-item>
                  </div>
                </div>
              </div>
              <div class="column is-5">
                <div class="td">
                  <div class="has-text-black has-text-weight-bold">
                    {{ $t('Property Name') }}
                  </div>
                  <div class="mt-1">
                    {{ $t('Property') }}
                  </div>
                  <div v-for="(id, i) in form.properties" :key="id" class="mb-2">
                    <div class="is-flex is-align-items-center">
                      <InputCompetitor
                        :value="id"
                        @change="handlePropertyChange($event, i)"
                      />
                      <a-button
                        v-if="form.properties.length > 1"
                        type="link"
                        ghost
                        @click="removeProperty(i)"
                      >
                        <CloseOutlined
                          style="font-size: 140%; color: red;"
                        />
                      </a-button>
                    </div>
                  </div>
                  <div class="mb-2">
                    <a-button
                      :size="size"
                      class="button-secondary-outline m-r-3x"
                      @click="addProperty"
                    >
                      {{ $t('Add property') }}
                    </a-button>
                  </div>
                </div>
              </div>
            </div>

            <hr>

            <div class="is-flex is-justify-content-end">
              <div v-if="successAlert || errorAlert" class="mr-4">
                <a-alert
                  :message="successAlert || errorAlert"
                  :type="successAlert ? 'success' : 'error'"
                />
              </div>
              <a-button
                v-permission="['modify-rms']"
                :size="size"
                :loading="isSubmitting"
                style="width: 100px;"
                class="button-primary m-r-3x"
                type="primary"
                @click="handleSubmit"
              >
                {{ $t('Save') }}
              </a-button>
            </div>
          </div>
        </div>
      </a-form>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Competitor Setting": "Competitor Setting",
    "Radius Search": "Radius Search",
    "Radius (km) from property itself": "Radius (km) from property itself",
    "Property Name": "Property Name",
    "Property": "Property",
    "Add property": "Add property",
    "competitor-saved": "Competitor setting have been saved."
  },
  "ja": {
    "Competitor Setting": "競合設定",
    "Radius Search": "競合検索",
    "Radius (km) from property itself": "物件からの距離 半径（km)",
    "Property Name": "物件名称",
    "Property": "物件名称",
    "Add property": "物件を追加する",
    "competitor-saved": "競合設定が保存されました"
  }
}
</i18n>

<script>
import { mapState } from 'vuex';
import { CloseOutlined } from '@ant-design/icons-vue';
import InputCompetitor from './components/InputCompetitor';

const alertTexts = {
  radius: {
    required: 'Radius is required and must be valid',
  },
  property: {
    required: 'All properties is required',
    empty: 'Property cannot be empty',
  },
  successAlert: 'competitor-saved',
};

export default {
  name: 'RMSCompetitor',
  components: {
    InputCompetitor,
    CloseOutlined,
  },
  data() {
    return {
      isSubmitting: false,
      size: 'large',
      form: {
        radius: undefined,
        properties: [null],
      },
      errors: {},
      successAlert: undefined,
      errorAlert: undefined,
    };
  },
  computed: {
    ...mapState('rms-settings', ['competitor', 'loading']),
  },
  created() {
    let waitInterval;
    this.populateFormData(waitInterval);

    if (!this.competitor) {
      waitInterval = setInterval(() => {
        this.populateFormData(waitInterval);
      }, 100);
    }
  },
  methods: {
    handlePropertyChange(event, index) {
      this.form.properties[index] = event;
    },
    addProperty() {
      this.form.properties.push(null);
    },
    removeProperty(index) {
      this.form.properties.splice(index, 1);
    },
    async handleSubmit() {
      try {
        this.isSubmitting = true;
        this.successAlert = undefined;
        this.errorAlert = undefined;

        const validation = this.handleValidation();
        if (validation) {
          throw validation;
        }

        await this.$store.dispatch('rms-settings/saveCompetitor', {
          ...this.form, settingId: this.$route.params.id,
        });

        this.isSubmitting = false;
        this.successAlert = this.$t(alertTexts.successAlert);
      } catch (error) {
        this.errorAlert = error;
        this.isSubmitting = false;
      }
    },
    validateForm() {
      this.errors.radius = undefined;

      if (!Number(this.form.radius)) {
        this.errors.radius = this.$t(alertTexts.radius.required);
      }
    },
    handleValidation() {
      this.validateForm();

      if (this.errors.radius) {
        throw this.errors.radius;
      }

      const emptyProperty = this.form.properties.some((property) => !property);
      if (emptyProperty) {
        throw this.$t(alertTexts.property.required);
      }

      if (this.form.properties.length === 0) {
        throw this.$t(alertTexts.property.empty);
      }

      return false;
    },
    populateFormData(wait) {
      if (this.competitor) {
        Object.keys(this.form).forEach((property) => {
          this.form[property] = this.competitor[property];
        });

        clearInterval(wait);
      }
    },
  },
};
</script>
