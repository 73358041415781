<template>
  <a-select
    v-model:value="id"
    show-search
    :placeholder="placeholder"
    style="width: 100%"
    :default-active-first-option="false"
    :filter-option="false"
    :not-found-content="$t('Competitor not found')"
    :size="size"
    @search="delaySearch"
    @change="handleChange"
  >
    <a-select-option v-for="d in data" :key="d.id" :title="d.name">
      {{ d.name }}
    </a-select-option>
  </a-select>
</template>

<i18n>
{
  "en": {
    "Competitor not found": "Competitor not found"
  },
  "ja": {
    "Competitor not found": "競合がみつかりませんでした"
  }
}
</i18n>

<script>
import debounce from 'lodash/debounce';

export default {
  name: 'InputCompetitor',
  props: {
    value: {
      type: String,
      default() {
        return undefined;
      },
    },
    size: {
      type: String,
      default() {
        return 'large';
      },
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  emits: ['change', 'update:value'],
  data() {
    return {
      data: [],
      id: undefined,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        if (nv && this.id !== nv) {
          this.id = nv;
        }
      },
    },
  },
  created() {
    this.handleSearch();
  },
  methods: {
    async handleSearch(value = '') {
      const params = { pageSize: 100 };

      if (this.id) {
        params.id = this.id;
      }

      if (value.length > 0) {
        delete params.id;
        params.q = value;
      }

      return this.$store
        .dispatch('rms-competitor-hotels/list', params)
        .then((res) => {
          this.data = res.data;

          return true;
        });
    },
    delaySearch: debounce(function delaySearch(value) {
      this.handleSearch(value);
    }, 500),
    handleChange(value) {
      this.id = value;
      this.$emit('change', this.id);
      this.$emit('update:value', this.id);
    },
  },
};
</script>
