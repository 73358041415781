<template>
  <div class="section">
    <div class="container">
      <div class="columns is-large-gap">
        <!-- start of left section -->
        <div class="column is-6">
          <h2 class="has-text-primary has-text-weight-bold is-size-4 m-b-3x">
            {{ $t('Overview') }}
          </h2>
          <div class="columns columns-table is-gapless is-mobile is-size-7 m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('RMS Detail') }}
              </div>
            </div>
            <div class="column has-text-right is-narrow">
              <div class="th">
                <Modal>
                  <template #default="{ hide }">
                    <RMSEditForm
                      :value="rmsSetting"
                      @close="
                        () => {
                          hide();
                          fetchData();
                        }
                      "
                      @cancel="hide"
                    />
                  </template>
                  <template #handler="{ show }">
                    <a
                      v-permission="['modify-rms']"
                      class="has-text-white"
                      @click.prevent="show"
                    >
                      <IconBase width="13" height="13" icon-name="edit">
                        <IconEdit />
                      </IconBase>
                      {{ $t('Edit') }}</a>
                  </template>
                </Modal>
              </div>
            </div>
          </div>
          <div class="columns columns-table is-gapless is-multiline is-size-7 m-b-0">
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('RMS Name') }}
                </div>
                <div>{{ rmsSetting.name }}</div>
              </div>
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Property') }}
                </div>
                <div>
                  {{ rmsSetting.property ? rmsSetting.property.name : rmsSetting.propertyId }}
                </div>
              </div>
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Currency') }}
                </div>
                <div>{{ rmsSetting.currency }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Upper Limit') }}
                </div>
                <div>{{ rmsSetting.upperLimit }}</div>
              </div>
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Pricing Strategy') }}
                </div>
                <div>{{ $t(`${rmsSetting.pricingStrategy}`) }}</div>
              </div>
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Maximum Discount Rate') }}
                </div>
                <div>{{ rmsSetting.maxDiscountRate }}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- start of right section -->
        <div
          v-permission="['modify-rms']"
          class="column is-6"
        >
          <h2 class="has-text-primary has-text-weight-bold is-size-4 m-b-3x">
            {{ $t('Permission') }}
          </h2>
          <div class="columns is-mobile">
            <div class="column">
              <div class="has-text-primary has-text-weight-bold">
                {{ $t('RMS Status') }}
              </div>
            </div>
            <div class="column is-narrow">
              <a-switch v-model:checked="status" :loading="submitting" />
            </div>
          </div>
          <hr>
          <div class="columns is-mobile">
            <div class="column">
              <div class="has-text-danger has-text-weight-bold">
                {{ $t('Delete RMS') }}
              </div>
              <div>{{ $t('Once deleted, you cannot recover this RMS. Please be certain.') }}</div>
            </div>
            <div class="column is-narrow">
              <DelRmsSetting :record="rmsSetting" @delete="toRmsLists">
                <template #handler="{ show }">
                  <a-button
                    type="danger"
                    @click="show"
                  >
                    {{ $t('Delete') }}
                  </a-button>
                </template>
              </DelRmsSetting>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- eslint-disable -->
<i18n>
{
  "en": {
    "Overview": "Overview",
    "RMS Detail": "RMS Detail",
    "RMS Name": "RMS Name",
    "Upper Limit": "Upper Limit",
    "Pricing Strategy": "Pricing Strategy",
    "Maximum Discount Rate": "Maximum Discount Rate",
    "RMS Status": "RMS Status",
    "Delete RMS": "Delete RMS",
    "Once deleted, you cannot recover this RMS. Please be certain.": "Once deleted, you cannot recover this RMS. Please be certain.",
    "start low and top up": "start low and top up",
    "start high and discount": "start high and discount",
  },
  "ja": {
    "Overview": "概要",
    "RMS Detail": "RMS詳細",
    "RMS Name": "RMS名称",
    "Upper Limit": "上限金額",
    "Pricing Strategy": "価格戦略",
    "Maximum Discount Rate": "最大割引率",
    "RMS Status": "RMSステータス",
    "Delete RMS": "RMSを削除",
    "Once deleted, you cannot recover this RMS. Please be certain.": "１度消したRMSを修復することはできません。削除前にご確認ください",
    "start low and top up": "安値で初期設定し値上げ",
    "start high and discount": "高値で初期設定し値下げ",
  }
}
</i18n>
<!-- eslint-enable -->

<script>
import { mapState } from 'vuex';
import Modal from '@/components/Modal';
import IconEdit from '@/components/icons/IconEdit';
import DelRmsSetting from './components/overview/DelRmsSetting';
import RMSEditForm from './components/overview/RmsForm';

export default {
  name: 'RMSOverview',
  components: {
    Modal,
    IconEdit,
    DelRmsSetting,
    RMSEditForm,
  },
  data() {
    return {
      submitting: false,
    };
  },
  computed: {
    rmsSetting() {
      return this.rms || {};
    },
    status: {
      get() {
        return (this.rms && this.rms.status === 'active') || false;
      },
      set(val) {
        this.submitting = true;
        this.$store.dispatch('rms-settings/updateRmsStatus', {
          status: val ? 'active' : 'inactive',
        }).then(() => {
          this.submitting = false;
        });
      },
    },
    ...mapState('rms-settings', ['rms', 'loading']),
  },
  methods: {
    toRmsLists() {
      this.$router.push({ name: 'revenue-management-settings' });
    },
    fetchData() {
      this.$store.dispatch('rms-settings/getRms', { id: this.$route.params.id });
    },
  },
};
</script>
