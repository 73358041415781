<template>
  <Delete
    v-permission="['modify-rms']"
    action-handler="rms-settings/deleteRms"
    :payload-value="{ id: record.id }"
    :title="$t('title')"
    :description="$t('desc', { name: record.name })"
    :success-title="$t('deleted-title')"
    :success-description="$t('deleted-desc', { name: record.name })"
    @close="$emit('delete')"
  >
    <template #handler="{ show }">
      <slot name="handler" :show="show" />
    </template>
  </Delete>
</template>

<!-- eslint-disable -->
<i18n>
{
"en": {
"title": "Delete RMS?",
"desc": "Are you use you want to delete RMS <strong>{name}</strong> ?<br> Once deleted, you cannot recover this RMS and its data.",
"deleted-title": "RMS is deleted",
"deleted-desc": "RMS <strong>{name}</strong> has been removed."
},
"ja": {
"title": "物件削除?",
"desc": "本当に <strong>{name}</strong> を削除しますか？<br>一度削除するとこの取引先とデータは復旧できません。",
"deleted-title": "プロパティを削除",
"deleted-desc": "{name} は削除されてます"
}
}
</i18n>
<!-- eslint-enable -->

<script>
import Delete from '@/components/PopupDelete';

export default {
  name: 'DelRMSSetting',
  components: {
    Delete,
  },
  props: {
    record: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['delete'],
};
</script>
