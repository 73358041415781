<template>
  <a-input
    v-model:value="displayValue"
    :size="size"
    type="text"
    style="width: 120px;"
    @input="formatInput"
    @focus="isInputActive = true"
  />
</template>

<script>

export default {
  name: 'InputFormattedNumber',
  props: {
    value: {
      default: 0,
      type: Number,
    },
    size: {
      type: String,
      default() {
        return 'large';
      },
    },
  },
  emits: ['input'],
  data() {
    return {
      displayValue: undefined,
      isInputActive: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        this.displayValue = this.createTempValue(nv) || undefined;
      },
    },
  },
  methods: {
    formatInput(val) {
      if (!val || !this.displayValue) {
        this.displayValue = undefined;
        this.$emit('input', undefined, undefined);
      }

      const tmp = this.createTempValue(this.displayValue);
      this.displayValue = tmp;
      this.$emit('input', Number(tmp.replace(/[^\d.]/g, '')), val.target.id);
    },
    formatNumber(n) {
      // format number to comma separated
      return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    createTempValue(val) {
      if (!val) {
        return undefined;
      }

      let tmp = val.toString();
      if (tmp.indexOf('.') >= 0) {
        // get position of first decimal
        // this prevents multiple decimals from
        // being entered
        const decimalPos = tmp.indexOf('.');

        // split number by decimal point
        let leftSide = tmp.substring(0, decimalPos);
        let rightSide = tmp.substring(decimalPos);

        // add commas to left side of number
        leftSide = this.formatNumber(leftSide);

        // validate right side
        rightSide = this.formatNumber(rightSide);

        if (!this.isInputActive) {
          tmp += '00';
        }
        rightSide = rightSide.substring(0, 2);
        tmp = `${leftSide}.${rightSide}`;
      } else {
        tmp = this.formatNumber(tmp);
        if (!this.isInputActive) {
          tmp += '';
        }
      }

      return tmp;
    },
  },
};
</script>
