{
    "en": {
      "Jan is required": "Jan is required.",
      "Feb is required": "Feb is required.",
      "Mar is required": "Mar is required.",
      "Apr is required": "Apr is required.",
      "May is required": "May is required.",
      "Jun is required": "Jun is required.",
      "Jul is required": "Jul is required.",
      "Aug is required": "Aug is required.",
      "Sep is required": "Sep is required.",
      "Oct is required": "Oct is required.",
      "Nov is required": "Nov is required.",
      "Dec is required": "Dec is required."
    },
    "ja": {
      "Jan is required": "値が未入力です",
      "Feb is required": "値が未入力です",
      "Mar is required": "値が未入力です",
      "Apr is required": "値が未入力です",
      "May is required": "値が未入力です",
      "Jun is required": "値が未入力です",
      "Jul is required": "値が未入力です",
      "Aug is required": "値が未入力です",
      "Sep is required": "値が未入力です",
      "Oct is required": "値が未入力です",
      "Nov is required": "値が未入力です",
      "Dec is required": "値が未入力です"
    }
  }
