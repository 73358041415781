<template>
  <div class="user">
    <template v-if="!loading">
      <AdminSubMenu v-if="rms" :menu="menu" :sub-menu="subMenu">
        {{ rms.name }}
      </AdminSubMenu>
    </template>
    <router-view />
  </div>
</template>

<!-- eslint-disable -->
<i18n>
{
  "en": {
    "Overview": "Overview",
    "Competitor": "Competitor",
    "GMS Target": "GMS Target",
    "Fraction": "Fraction"
  },
  "ja": {
    "Overview": "概要",
    "Competitor": "競合設定",
    "GMS Target": "目標GMS",
    "Fraction": "端数設定"
  }
}
</i18n>
<!-- eslint-enable -->

<script>
import { mapState } from 'vuex';
import AdminSubMenu from '@/components/AdminSubMenu';
import previousRoute from '@/components/mixins/previousRoute';

export default {
  name: 'RMSSettingDetails',
  components: {
    AdminSubMenu,
  },
  mixins: [previousRoute],
  data() {
    return {
      menuName: 'revenue-management-settings',
    };
  },
  computed: {
    menu() {
      return {
        query: this.previousRouteQuery,
        name: this.menuName,
        title: this.$t('Back'),
      };
    },
    subMenu() {
      return [
        {
          name: 'revenue-management-settings-overview',
          title: this.$t('Overview'),
        },
        {
          name: 'revenue-management-settings-competitor',
          title: this.$t('Competitor'),
        },
        {
          name: 'revenue-management-settings-gms',
          title: this.$t('GMS Target'),
        },
        {
          name: 'revenue-management-settings-fraction',
          title: this.$t('Fraction'),
        },
      ];
    },
    ...mapState('rms-settings', ['rms', 'loading']),
  },
  created() {
    this.asyncData();
  },
  methods: {
    async asyncData(route = null) {
      route = route || this.$route;

      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.$store
        .dispatch('rms-settings/getRms', { id: route.params.id })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
  },
};
</script>
