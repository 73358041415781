import { mapState } from 'vuex';

export default {
  computed: {
    previousRouteQuery() {
      if (this.firstLevelRoute) {
        if (this.firstLevelRoute.name === this.menuName) {
          return this.firstLevelRoute.query;
        }
      }

      return undefined;
    },
    ...mapState('routers', ['firstLevelRoute']),
  },
};
